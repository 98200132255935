import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RendicontazioneCloudService {

  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) { }

  // Get_Commesse(IDDipendente) {
  //   this._global.GETendpointCURRENT();
  //   let postData = {
  //     "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
  //     "CodiceUnita" : this._global.GETCodiceUnita(),
  //     "PIVA" :  this._global.GETPIVA(),
  //     "Stato" : 'APE',
  //     "IDDipendente" : IDDipendente,
  //   }
  //   console.log('postData Getcommesse : ');
  //   console.log(JSON.stringify(postData));
  //   let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
  //   return this.httpClient.post(this._global.myURL + "GET_Commesse", postData, headers) 
  // }
  
  // Get_CommesseFasi(IDCommessa, IDDipendente) {
  //   this._global.GETendpointCURRENT();
  //   let postData = {
  //     "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
  //     "CodiceUnita" : this._global.GETCodiceUnita(),
  //     "PIVA" :  this._global.GETPIVA(),
  //     "IDCommessa" : IDCommessa,
  //     "Stato" : 'APE',
  //     "IDDipendente" : IDDipendente,
  //   }
  //   console.log('postData Get_CommesseFasi : ');
  //   console.log(JSON.stringify(postData));
  //   let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
  //   return this.httpClient.post(this._global.myURL + "GET_CommesseFasi", postData, headers) 
  // }

  Get_Commesse(IDDipendente, IDCliente) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "IDCliente" : IDCliente,
      "Stato" : 'APE',
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_Commesse", postData, headers) 
  }
  
  Get_CommesseFasi(DataSearch, IDDipendente, IDCommessa, AllSubFasi) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDCommessa" : IDCommessa,
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "Stato" : 'APE',
      "DataSearch" : DataSearch,
      "AllSubFasi" : AllSubFasi,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_CommesseFasi", postData, headers) 
  }

  Get_CommesseClienti(IDDipendente) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "Stato" : 'APE',
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_Commesse_Clienti", postData, headers) 
  }

  Get_RendicontazioniDipendenti(IDCommessa, IDFase, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "PIVA" :  this._global.GETPIVA(),
      "IDCommessa" : IDCommessa,
      "IDFase" : IDFase,
      "Data" : Data,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_RendicontazioniDipendenti", postData, headers) 
  }

  Get_RendicontazioniMezzi(IDCommessa, IDFase, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "PIVA" :  this._global.GETPIVA(),
      "IDCommessa" : IDCommessa,
      "IDFase" : IDFase,
      "Data" : Data,
    }
    console.log('GET_RENDICONTAZIONIMEZZI : ',JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_RendicontazioniMezzi", postData, headers) 
  }

  Save_Rendicontazioni(x) {
    this._global.GETendpointCURRENT();
    let postData = {"ListRendicontazioni": x};
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "SAVE_Rendicontazioni", postData, headers) 
  }


  Get_Anagrafiche(IDGruppoSistema, IDAnagrafica) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDGruppoSistema" : IDGruppoSistema,
      "IDAnagrafica" : IDAnagrafica,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GetAnagrafiche", postData, headers) 
  }

  Get_Rendicontazioni(IDCommessa, IDFase, Data, IDDipendente, Stato) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "PIVA" :  this._global.GETPIVA(),
      "IDCommessa" : IDCommessa,
      "IDFase" : IDFase,
      "Data" : Data,
      "IDDipendente" :  IDDipendente,
      "Stato" : Stato,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_Rendicontazioni", postData, headers) 
  }

  DELRendicontazione(IDRendicontazione) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDRendicontazione" :  IDRendicontazione,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "DEL_Rendicontazioni", postData, headers) 
  }

  
  Conferma_Rendicontazioni(Stato, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "Stato" :  Stato,
      "Data" :  Data,
      "IDUtenteConferma" : this._global.GETIDUtente(),
      "UtenteConferma" : this._global.GETUsername(),
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "CONFERMA_Rendicontazioni", postData, headers) 
  }

  Conferma_RendicontazioniNote(Stato, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "Stato" :  Stato,
      "Data" :  Data,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "CONFERMA_RendicontazioniNote", postData, headers) 
  }

  Conferma_OggettiAllegati(Stato, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "Stato" :  Stato,
      "Data" :  Data,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "CONFERMA_OggettiAllegati", postData, headers) 
  }

  GetLastData_Rendicontazioni(Stato) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "Stato" :  Stato,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "LastDataRendicontazioni", postData, headers) 
  }

  Get_Attivita(IDDipendente, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDOggettoOperatore" : IDDipendente,
      "DataOraInizio" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_Attivita", postData, headers) 
  }

  SUM_Attivita_Totale(IDDipendente, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDOggettoOperatore" : IDDipendente,
      "DataOraInizio" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_SUM_Attivita", postData, headers) 
  }
 




  Get_RendicontazioneNote(IDOggetto, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDOggetto" : IDOggetto,
      "Data" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_RendicontazioniNote", postData, headers) 
  }


  GET_NoteTipologie() {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
    }
    console.log('GET_NoteTipologie : ', postData)
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_NoteTipologie", postData, headers)
  }


  GET_NoteTipologieAndNote(Data, IDOggetto) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "ShowNote" : true,
      "Data" : Data,
      "IDOggetto" : IDOggetto,
    }
    console.log('GET_Questionario : ', postData, this._global.myURL + "GET_NoteTipologie")
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_NoteTipologie", postData, headers)
  }

  Get_RendicontazioniSenzaNote() {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita()
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_RendicontazioniSenzaNote", postData, headers) 
  }

  Save_RendicontazioniNote(x) {
    this._global.GETendpointCURRENT();
    let postData = x;
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "SAVE_RendicontazioniNote", postData, headers) 
  }
  Save_RendicontazioniNoteMassiva(x) {
    this._global.GETendpointCURRENT();
    let postData = x;
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "SAVE_RendicontazioniNoteMassiva", postData, headers) 
  }

  INS_RendicontazioniNote(IDOggetto, Data, Nota, Stato) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDOggetto" : IDOggetto,
      "Data" :  Data,
      "Note" : Nota,
      "Stato" :  Stato
    };
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "INS_RendicontazioniNote", postData, headers) 
  }


  DELRendicontazioneNote(IDNota) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDNota" :  IDNota,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "DEL_RendicontazioniNote", postData, headers) 
  }




  Get_OggettiAllegati(IDOggetto, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDOggetto" : IDOggetto,
      "Data" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GET_OggettiAllegati", postData, headers) 
  }
 
  INS_OggettiAllegati(x) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,

    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "INS_OggettiAllegati", x, headers) 
  }
 

  DEL_OggettiAllegati(IDAllegato) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica" : this._global.GETIDLicenzaAnagrafica(),
      "CodiceUnita" : this._global.GETCodiceUnita(),
      "IDAllegato" :  IDAllegato,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "DEL_OggettiAllegati", postData, headers) 
  }


   
}
