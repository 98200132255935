import { HttpClient } from '@angular/common/http';
import { Component, NgModule, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, LoadingController, NavController, AlertController, ToastController, IonSelectOption, IonText, Platform } from '@ionic/angular';
import { RendicontazioneService } from '../../services/rendicontazione.service';
import { RendicontazioneCloudService } from 'src/app/services/rendicontazione-cloud.service';
import { GlobalService } from '../../services/global.service';
import { ModalController } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
import { DatePipe } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { AnagraficheService } from '../../services/anagrafiche.service';
import moment from 'moment';


@Component({
  selector: 'app-rendicontazioni-list',
  templateUrl: './rendicontazioni-list.page.html',
  styleUrls: ['./rendicontazioni-list.page.scss'],
})


export class RendicontazioniListPage {
  @ViewChild('cmbDip') cmbDip: IonSelect;

  slidesOptions = {
    slidesPerView:2.2
  }

  dataOption = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  public current_data: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  public IDDipendente: number = 0;
  public ListDipendenti: any = [];
  public ListAttivitaSumTipo: any = [];
  public ListAttivitaSumTotale: any = [];
  public ListCommesse: any = [];
  public ListCommesseClienti: any = [];
  public ListFasi: any = [];
  public AttivitaTipologie: any = [];
  public AttivitaPianificate: any = [];

  public ListAttivita: any = [];
  public selAttivita: any = [];
  public selIDAttivita;

  public ListRendicontazioni: any = [];
  public selIDRendicontazione;
  public selRendicontazione;

  public SaveMode: string = "EDIT";
  public cnMode : string ='';
  public isIOS : boolean = false;


  public bCopyToDate: boolean = false;
  public CopyToDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

//#region CALENDAR MODAL
  async openCalendar() {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    defDate = this.current_data;

    const options: CalendarModalOptions = {
      title: '',
      color: 'primary',
      canBackwardsSelected: true,
      from: new Date(2017, 1, 1),
      defaultDate: defDate,
      defaultScrollTo: defDate
    };

    let myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      componentProps: { options }
    });

    myCalendar.present();

    loading.dismiss();

    const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    this.current_data = this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
  }
//#endregion

  constructor(public platform: Platform, public datepipe: DatePipe, public modalCtrl: ModalController, public alertCtrl: AlertController, public toastController: ToastController, public httpClient: HttpClient, private router: Router, private _mysrv: RendicontazioneService,  private _mysrvCloud: RendicontazioneCloudService,  private navCtrl: NavController, public _myglobal: GlobalService, private _srvanag: AnagraficheService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    this.cnMode = _myglobal.GETcnMode();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    {
      this.navCtrl.navigateRoot('/login');
    }

    if(this.platform.is("ios") || this.platform.is("mobileweb") ){
      this.isIOS=true;
    }

  }

  ionViewDidEnter(){
    this._myglobal.GETUserData();
    this.RefreshSquential();
  }

  ionViewDidLeave(){
    console.log("ION LEAVE");
    this.ListAttivita=[];
    this.selAttivita = {};
  
  }

  // ngOnInit() {
  //   this._myglobal.GETUserData();
  //   this.RefreshSquential();
  // }

  async RefreshSquential() {
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    this.ListAttivita=[];
    //CARICO I DIPENDENTI
    let IDDipendenteLogged;
    IDDipendenteLogged = this._myglobal.GETIDDipendente();
    if (IDDipendenteLogged>0 && this._myglobal.GETUserType() == "ADMIN") {
      IDDipendenteLogged = -9999;
    }
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
              this.ListDipendenti = data;
              this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
              console.log('LOAD DIPENDENTI');
              console.log (this.ListDipendenti);
              loading.dismiss();
              this.RefreshDataSquential();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
              this.ListDipendenti = data;
              this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
              console.log('LOAD DIPENDENTI');
              console.log (this.ListDipendenti);
              loading.dismiss();
              this.RefreshDataSquential();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
    }
  }

  async RefreshDataSquential() {
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
        this.ListAttivita=[];
        this.selAttivita = {};
        if (this.cnMode=="LOCAL") {
        //CARICA I CLIENTI COMMESSE
        this._mysrv.Get_CommesseClienti(this.IDDipendente).subscribe((data : any) => {
          this.ListCommesseClienti = data;
          console.log('LOAD CLI COMM');
          console.log (this.ListCommesseClienti);

          //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
          this._mysrv.Get_Commesse(this.IDDipendente, -9999).subscribe((data : any) => {
            this.ListCommesse = data;
            console.log('LOAD Comm');
            console.log (this.ListFasi);


            this._mysrv.Get_CommesseFasi(this.current_data, this.IDDipendente, -1, 1).subscribe((data : any) => {
              this.ListFasi = data;
              if (this.SaveMode=="ADD") {
                this.ListFasi.forEach(f => {
                  if (f.IsDefault) {
                    this.selAttivita.IDCommessaFase=f.IDFase;
                  } else {
                    this.selAttivita.IDCommessaFase=-1;
                  }
                });
              } 
              console.log('LOAD FasiComm');
              console.log (this.ListFasi);
              loading.dismiss();
              this._loadAttivita();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
          }, error => {
          console.log(error);
          loading.dismiss();
          });
        }, error => {
          console.log(error);
          loading.dismiss();
        }); 
      }
      if (this.cnMode=="CLOUD") {
          //CARICA I CLIENTI COMMESSE
        this._mysrvCloud.Get_CommesseClienti(this.IDDipendente).subscribe((data : any) => {
          this.ListCommesseClienti = data;
          console.log('LOAD CLI COMM');
          console.log (this.ListCommesseClienti);

          //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
          this._mysrvCloud.Get_Commesse(this.IDDipendente, -9999).subscribe((data : any) => {
            this.ListCommesse = data;
            console.log('LOAD Comm');
            console.log (this.ListFasi);

            this._mysrvCloud.Get_CommesseFasi(this.current_data, this.IDDipendente, -1, 1).subscribe((data : any) => {
              this.ListFasi = data;
              if (this.SaveMode=="ADD") {
                this.ListFasi.forEach(f => {
                  if (f.IsDefault) {
                    this.selAttivita.IDCommessaFase=f.IDFase;
                  } else {
                    this.selAttivita.IDCommessaFase=-1;
                  }
                });
              } 
              console.log('LOAD FasiComm');
              console.log (this.ListFasi);
              loading.dismiss();
              this._loadAttivita();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
          }, error => {
          console.log(error);
          loading.dismiss();
          });
        }, error => {
          console.log(error);
          loading.dismiss();
        }); 
      }
  }

  doRefresh(event) {
    this.RefreshDataSquential();
    event.target.complete();
   }
 
   async _loadAttivita(){
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    //CARICO LE ATTIVITA PER IL DIPENDENTE E DATA
    this.isloading=true;
    console.log('PRE LOAD ATTIVITA : IDDipendente : ' + this.IDDipendente);
    this.ListAttivita = [];
    this.ListRendicontazioni = [];
    //this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
    //console.log(' LOAD ATTIVITA : IDDipendente : ' + this.IDDipendente);
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Attivita(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivita = data;
         
          this.isloading=false;
          console.log('LOAD ATTIVITA');
          console.log (this.ListAttivita);
          loading.dismiss();
          this._loadAttivitaSummaries();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Rendicontazioni(-1,-1,this.current_data, this.IDDipendente, "").subscribe((data : any) => {
          this.ListRendicontazioni = data;
          this.ListRendicontazioni.forEach(el => {
        
            if (el.DataOraInizio==null || el.DataOraInizio==undefined || parseInt(el.DataOraInizio.slice(0,4))<=1970) { el.DataOraInizio ="";}
            if (el.DataOraFine==null || el.DataOraFine==undefined || parseInt(el.DataOraFine.slice(0,4))<=1970) { el.DataOraFine ="";}
          });
          this.isloading=false;
          console.log('LOAD ATTIVITA');
          console.log (this.ListRendicontazioni);
          loading.dismiss();
          this._loadAttivitaSummaries();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
    }
  }

  async _loadAttivitaSummaries(){
    //CARICO LE ATTIVITA PER IL DIPENDENTE E DATA
   
    if (this.cnMode=="LOCAL") {
      this._mysrv.SUM_Attivita_Per_Tipo(this.IDDipendente, this.current_data).subscribe((data : any) => {
        this.ListAttivitaSumTipo = data;
        console.log('LOAD ATTIVITA SUM Tipi');
        console.log (this.ListAttivitaSumTipo);
      }, error => {
        console.log(error);
      });
        this._mysrv.SUM_Attivita_Totale(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivitaSumTotale = data;
          console.log('LOAD ATTIVITA TOTALE');
          console.log (this.ListAttivitaSumTotale);
      }, error => {
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
        this._mysrvCloud.SUM_Attivita_Totale(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivitaSumTotale = data;
          console.log('LOAD ATTIVITA TOTALE CLOUD');
          console.log (this.ListAttivitaSumTotale);
      }, error => {
        console.log(error);
      });
    }
  }

   async _loadDipendenti(){
    //CARICO I DIPENDENTI
    let IDDipendenteLogged;
    IDDipendenteLogged = this._myglobal.GETIDDipendente();
    if (IDDipendenteLogged>0 && this._myglobal.GETUserType() == "ADMIN") {
      IDDipendenteLogged = -9999;
    }
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
          this.ListDipendenti = data;
          this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
          console.log('LOAD DIPENDENTI');
          console.log (this.ListDipendenti);
      }, error => {
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
          this.ListDipendenti = data;
          this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
          console.log('LOAD DIPENDENTI');
          console.log (this.ListDipendenti);
      }, error => {
        console.log(error);
      });
    }
  }

  async _loadCommesse(IDDipendente, IDCliente){
    //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Commesse(IDDipendente, IDCliente).subscribe((data : any) => {
          this.ListCommesse = data;
          this.selAttivita.IDOggettoGeneratore = data[0].IDCommessa;
          console.log('LOAD Comm');
          console.log (this.ListFasi);
      }, error => {
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Commesse(IDDipendente, IDCliente).subscribe((data : any) => {
          this.ListCommesse = data;
          console.log('LOAD Comm');
          console.log (this.ListFasi);
      }, error => {
        console.log(error);
      });
    }
    
  }

  async _loadFasiCommesse(IDDipendente, IDCommessa){
    //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_CommesseFasi(this.current_data,IDDipendente, IDCommessa, 1).subscribe((data : any) => {
          this.ListFasi = data;
          if (this.SaveMode=="ADD") {
            this.ListFasi.forEach(f => {
              if (f.IsDefault) {
                this.selAttivita.IDCommessaFase=f.IDFase;
              } else {
                this.selAttivita.IDCommessaFase=data[0].IDFase;
              }
            });
          } 
          console.log('LOAD FasiComm');
          console.log (this.ListFasi);
      }, error => {
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_CommesseFasi(this.current_data,IDDipendente, IDCommessa, 1).subscribe((data : any) => {
          this.ListFasi = data;
          if (this.SaveMode=="ADD") {
            this.ListFasi.forEach(f => {
              if (f.IsDefault) {
                this.selAttivita.IDCommessaFase=f.IDFase;
              } else {
                this.selAttivita.IDCommessaFase=-1;
              }
            });
          } 
          console.log('LOAD FasiComm');
          console.log (this.ListFasi);
      }, error => {
        console.log(error);
      });
    }
  }

  async _loadCommesseClienti(){
    //CARICA I CLIENTI COMMESSE
    const loading = await this.loadingController.create({
      message: 'Caricamento Commesse...',
    });
    await loading.present();
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_CommesseClienti(this.IDDipendente).subscribe((data : any) => {
        this.ListCommesseClienti = data;
        console.log('LOAD CLI COMM');
        console.log (this.ListCommesseClienti);
        loading.dismiss();  
      }, error => {
        loading.dismiss();  
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_CommesseClienti(this.IDDipendente).subscribe((data : any) => {
        this.ListCommesseClienti = data;
        console.log('LOAD CLI COMM');
        console.log (this.ListCommesseClienti);
        loading.dismiss();  
      }, error => {
        loading.dismiss();  
        console.log(error);
      });
    }
  }

  navigateback() {
    if (this._myglobal.isEdit) {
    this._myglobal.isEdit = false;
    this.selAttivita = {};
    this.ListAttivita =[];
    this._loadAttivita();
    } else {
      this.navCtrl.back();
    }
  }

  // async _loadAttivitaTipologie(){
  //   //CARICO LE TIPOLOGIE DELLE ATTIVITA
  //   this._mysrv.Get_AttivitaTipologie().subscribe((data : any) => {
  //       this.AttivitaTipologie = data;
  //   }, error => {
  //     console.log(error);
  //   });

  // }

  // async _loadAttivitaPianificate(IDCommessaFase){
  //   //CARICO LE TIPOLOGIE DELLE ATTIVITA
  //   this._mysrv.Get_AttivitaPianificate(IDCommessaFase).subscribe((data : any) => {
  //       this.AttivitaPianificate = data;
  //   }, error => {
  //     console.log(error);
  //   });

  // }

  public editRenMode="";

  selectAttivita(a){
    //CARICO L'ATTIVITA SELEZIONATA IN VARIABILE TEMPORANEA
    this.isloading=true; 
    console.log('ISLOADING : ' + this.isloading);
    this.SaveMode="EDIT";
    this._myglobal.isEdit = true;
    if (this.cnMode=="LOCAL") {
      this.selAttivita = a;
      this.selRendicontazione = [];
      if ((this.selAttivita.DataOraInizio.slice(11,16) == null || this.selAttivita.DataOraInizio.slice(11,16) == undefined || this.selAttivita.DataOraInizio.slice(11,16) == 'undefined' || this.selAttivita.DataOraInizio.slice(11,16) == '00:00' ) &&
      (this.selAttivita.DataOraFine.slice(11,16) == null || this.selAttivita.DataOraFine.slice(11,16) == undefined || this.selAttivita.DataOraFine.slice(11,16) == 'undefined' || this.selAttivita.DataOraFine.slice(11,16) == '00:00' )) {
        this.editRenMode="hour";
      } else {
          this.editRenMode="range";
      }
      this._loadCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDCliente);
      this._loadFasiCommesse(this.selAttivita.IDOggettoOperatore, this.selAttivita.IDOggettoGeneratore);
      // this.selAttivita = a;
      console.log('SELECT ATTIVITA');
      console.log(this.selAttivita);
      console.log('ISLOADING : ' + this.isloading);
      this.isloading=false;
    }
    if (this.cnMode=="CLOUD") {
      this.selAttivita = [];
      this.selRendicontazione = a;
      if ((this.selRendicontazione.DataOraInizio.slice(11,16) == null || this.selRendicontazione.DataOraInizio.slice(11,16) == undefined || this.selRendicontazione.DataOraInizio.slice(11,16) == 'undefined' || this.selRendicontazione.DataOraInizio.slice(11,16) == '00:00' ) &&
      (this.selRendicontazione.DataOraFine.slice(11,16) == null || this.selRendicontazione.DataOraFine.slice(11,16) == undefined || this.selRendicontazione.DataOraFine.slice(11,16) == 'undefined' || this.selRendicontazione.DataOraFine.slice(11,16) == '00:00' )) {
        this.editRenMode="hour";
      } else {
        if ( this._myglobal.RendicontazioneMode=="range" ||  this._myglobal.RendicontazioneMode =="rangess" ) {
          this.editRenMode=this._myglobal.RendicontazioneMode;
        } else {
          this.editRenMode="range";
        }
      }
      this._loadCommesse(this.selRendicontazione.IDDipendente,this.selRendicontazione.IDCliente);
      this._loadFasiCommesse(this.selRendicontazione.IDDipendente, this.selRendicontazione.IDCommessa);
      console.log('SELECT RENDIC', this.selRendicontazione);
      this.isloading=false;
    }
  }

  addAttivita() {
    //IMPOSTO LA MOPDALITA ADD E CARICO I DATI DI DEFAULT PER INSERT
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;
    let myData = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
    if (this._myglobal.RendicontazioneMode=="hour") {
      myData = this.datepipe.transform(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0,0),"yyyy-MM-dd HH:mm");
    }
    if (this.current_data != null && this.current_data != undefined && this.current_data != "") { myData = this.current_data;} 
    this.isloading=true;
    this.SaveMode = "ADD";
    this.selRendicontazione = [];
    this.selIDRendicontazione = -1;
    this.selAttivita = {
      "IDAttivita" : -1,
      "IDAttivitaCausale" : -1,
      "CodiceCausale" : "ORD",
      "IDOggettoOperatore" : this.IDDipendente,
      "IDOggettoGeneratore" : -1,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "DataOraInizio" :  this.current_data,
      "DataOraFine" : this.current_data,
      "Qta" : 0, 
      "QtaDurata" : new Date(new Date(new Date()).getFullYear(),new Date(new Date()).getMonth(),new Date(new Date()).getDate(), 0, 0)
    };
    this.selIDAttivita = -1;
    this._myglobal.isEdit=true;
    this.isloading=false;
    console.log('ADD ATTIVITA');
      console.log(this.selAttivita);
  }
  addRendicontazione() {
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;
    this._myglobal.isEdit=true;
    let myData = this.datepipe.transform(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0,0),"yyyy-MM-dd HH:mm");
      this.isloading=true;
      this.SaveMode="ADD";
      this.selAttivita = [];
      this.selIDAttivita = -1;
      this.selRendicontazione = {
        "IDRendicontazione" : -1,
        "IDLicenzaAnagrafica" : this._myglobal.IDLicenzaAnagrafica,
        "CodiceUnita" : this._myglobal.CodiceUnita,
        "PIVA" : this._myglobal.PIVA,
        "CodiceCausale" : "ORD",
        "IDDipendente" : this.IDDipendente,
        "IDCommessa" : -1,
        "IDFase" : -1,
        "Data" :  myData,
        "Ore" : 0, 
        "OreDurata" : new Date(new Date(new Date()).getFullYear(),new Date(new Date()).getMonth(),new Date(new Date()).getDate(), 0, 0),
        "DataOraInizio" : new Date(),
        "DataOraFine" : new Date(),
        "Note" : "",
        "Stato" : "INSERITO"
      };
      this.selIDRendicontazione = -1;
      this._myglobal.isEdit=true;
      this.isloading=false;
      console.log('ADD ATTIVITA');
      console.log(this.selRendicontazione);
  }

  init_worked_time() {
    var m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.toISOString();
    m.format();
    console.log(m);
    this.selRendicontazione.OreDurata = m;
    this.selAttivita.QtaDurata = m;
    console.log(this.selRendicontazione)
  }

  async DEL_Attivita(x) {
  //ELIMINAZIONE DELL ATTIVITA
    let alert = await this.alertCtrl.create({
      header: 'Next App',
      message: "Eliminare questa attivita?",
      buttons: [
        {
          text: 'Annulla', role: 'cancel', handler: () => { } },
        {
          text: 'Conferma',
          handler: () => {
            this.isloading=true;
             this._mysrv.DEL_Attivita(x.IDAttivita).subscribe((data : any) => {
              this._myglobal.isEdit = false;
              this.isloading=false;
              this._loadAttivita(); 
              console.log('DEL Attivita');
              this.presentAlert('Attività eliminata!');
            }, error => {
              this.isloading=false;
              console.log(error);
            });
          }
        }
      ]
    });
    await alert.present();
  }


  saveAttivita(x){
    //SALVO l'ATTIVITA 

    if (this.bCopyToDate) {
      this.isloading=true;
      console.log(this.SaveMode + ' ' + x.IDAttivita)
      const startDate = new Date(x.DataOraInizio);
      const endDate = new Date(this.CopyToDate);
      const datesToProcess: Date[] = [];
      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const formattedDayOfWeek = this.datepipe.transform(currentDate, 'EEEE');
        if (formattedDayOfWeek !== 'Saturday' && formattedDayOfWeek !== 'Sunday') {
          datesToProcess.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      for (const date of datesToProcess) {
      if ((this.SaveMode=="EDIT" && x.IDAttivita>0) || (this.SaveMode=="ADD")) {
          //x.DataOraInizio = date;
          console.log('Save Attivita pre ' + date, moment(date).format('yyyy-MM-DD'));
          x.DataOraInizio = moment(date).format('yyyy-MM-DD');
          console.log('Save Attivita '  , x.DataOraInizio);

            this._mysrv.UPD_Attivita(x).subscribe((data : any) => {
            this.selIDAttivita=0;
            this.selAttivita=[];
            this._myglobal.isEdit = false;
            this.SaveMode="EDIT";
            this.isloading=false;
            this._loadAttivita(); 
            console.log (this.ListAttivita);
            this.msg = "Attivita salvata!";
            this.showToast("success");
            //this.presentAlert('Attivita salvata!');
          }, error => {
            this.isloading=false;
            console.log(error);
          });
       }
      }
      return;
    }


    this.isloading=true;
    console.log(this.SaveMode + ' ' + x.IDAttivita)
     if ((this.SaveMode=="EDIT" && x.IDAttivita>0) || (this.SaveMode=="ADD")) {
        this._mysrv.UPD_Attivita(x).subscribe((data : any) => {
          this.selIDAttivita=0;
          this.selAttivita=[];
          this._myglobal.isEdit = false;
          this.SaveMode="EDIT";
          this.isloading=false;
          this._loadAttivita(); 
          console.log (this.ListAttivita);
          this.msg = "Attivita salvata!";
          this.showToast("success");
          //this.presentAlert('Attivita salvata!');
        }, error => {
          this.isloading=false;
          console.log(error);
        });
      }
  }

   
  
start(x){
  if(this.isloading==true) {return;}
  this.isloading=true;
  x.DataOraInizio=this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  this.isloading=false;
}

stop(x){
  if(this.isloading==true) {return;}
  this.isloading=true;
  x.DataOraFine=this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  this.isloading=false;
}


editRendicontazione(x) {
  this.isloading=true;
  this.SaveMode="EDIT";
  this._myglobal.isEdit=true;
  this._loadCommesse(x.IDDipendente, x.IDCliente).then((result) => {
    this._loadFasiCommesse(x.IDCommessa, x.IDDipendente).then((result) => {
      this.selRendicontazione = x;
    });
  });
  
  this.isloading=false;
}



async _saveRendicontazione(x){
  //SALVO
  if (x.IDCommessa == null || x.IDCommessa == '' || x.IDCommessa<=0) {
    this.presentAlert('Selezionare una commessa!');
    return;
  }
  if (x.IDFase == null || x.IDFase == '' || x.IDFase<=0) {
    this.presentAlert('Selezionare una fase di commessa!');
    return;
  }
  if (this.editRenMode !="rangess" && (x.Ore == null || x.Ore == '' || x.Ore<=0)) {
    this.presentAlert('Impostare una durata!');
    return;
  }
  if (this.editRenMode =="rangess") {
    if (x.DataOraInizio==null || x.DataOraInizio==undefined) { x.DataOraInizio="";}
    if (x.DataOraFine==null || x.DataOraFine==undefined) { x.DataOraFine="";}
    let dtStart: string ="";
    dtStart = x.DataOraInizio;
    let dtEnd: string ="";
    dtEnd = x.DataOraFine;
    console.log('AVE REND:', dtStart, dtEnd)
    if ((dtStart.slice(11,16) == '00:00' || dtStart=="" || dtStart =='') ||
    (dtEnd.slice(11,16) == '00:00' || dtEnd=="" || dtEnd == '')) {
      console.log('INTO IN CORSO');    
      x.Stato = "IN CORSO";
    } else {
       if (x.Stato == "IN CORSO") {
          x.Stato = "INSERITO";
        }
    }
  }
  //if (x.Data == null || x.Data == '' || x.Data==undefined) {
     x.Data = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate()),"yyyy-MM-dd");
  //}
  console.log('RET SAVE REND PREV:', x, x.Stato);
  let alert = await this.alertCtrl.create({
    header: 'Next App',
    message: "Salvare la rendicontazione?" ,
    buttons: [
      { text: 'Annulla', role: 'cancel', handler: () => { }
      },
      {
        text: 'Conferma',
        handler: () => {
            this._mysrvCloud.Save_Rendicontazioni([x]).subscribe((data : any) => {
                console.log('RET SAVE REND' , data);
                this._myglobal.isEdit = false;
                this.SaveMode="EDIT";
                if (data == null || String(data).startsWith("ERRORE") || String(data[0].Ret).startsWith("ERRORE")) {
                  this.presentAlert(String(data[0].Ret));
                } else {
                  this._loadAttivita();
                }
            }, error => {
              console.log(error);
            });
          }  
        }
    ]
  });
  await alert.present();
  

}


async DEL_Rendicontazione(x) {
  //SALVO
  let alert = await this.alertCtrl.create({
    header: 'Next App',
    message: "Eliminare la rendicontazione?" ,
    buttons: [
      { text: 'Annulla', role: 'cancel', handler: () => { }
      },
      {
        text: 'Conferma',
        handler: () => {
            this._mysrvCloud.DELRendicontazione(x.IDRendicontazione).subscribe((data : any) => {
              this._myglobal.isEdit = false;
                this.isloading=false;
                if (data == null || String(data).startsWith("ERRORE") || String(data[0].Ret).startsWith("ERRORE")) {
                  this.presentAlert(String(data[0].Ret));
                }  else {
                  this._loadAttivita();
                }
            }, error => {
              console.log(error);
            });
          }  
        }
    ]
  });
  await alert.present();
  }
  
 
//#region "CALCOLI TEMPI" 
// FUNZIONI PER IL CALCOLO DEI TEMPI E DATE
// isloading: boolean = false;
// calcTimerFromFine(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//     x.DataOraInizio = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes()),"yyyy-MM-dd HH:mm");
//     x.DataOraFine = this.datepipe.transform(new Date(new Date(x.DataOraFine).getFullYear(),new Date(x.DataOraFine).getMonth(),new Date(x.DataOraFine).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes()),"yyyy-MM-dd HH:mm");
//     console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//     let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
//     console.log('DIFF: ' + diff + '    H:' +diff.split(':')[0]);
//     x.QtaDurata=  diff.split(':')[0] + ':' + diff.split(':')[1];
//     console.log('QtaDurata: ' + x.QtaDurata);
//     this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
// //    return;
//     console.log('CALC TIMER > ISLOADING : ' + this.isloading, x.DataOraFine.slice(0,4));
//     if(this.isloading==true) {return;}
//     let dtEnd: string | null ="";
//     dtEnd = x.DataOraFine.toString();
//     if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//       this.isloading=true;
//       console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//       x.DataOraInizio = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes()),"yyyy-MM-dd HH:mm");
//       x.DataOraFine = this.datepipe.transform(new Date(new Date(x.DataOraFine).getFullYear(),new Date(x.DataOraFine).getMonth(),new Date(x.DataOraFine).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes()),"yyyy-MM-dd HH:mm");
//       console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//       let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
//       console.log('DIFF: ' + diff + '    H:' +diff.split(':')[0]);
//       x.OreDurata=  diff.split(':')[0] + ':' + diff.split(':')[1];
//       console.log('QtaDurata: ' + x.OreDurata);
//       this.isloading=false;
//     }
// }
// calcTimerFromOre(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER FROM ORE > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     x.DataOraFine = this.datepipe.transform(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Qta)*60)),"yyyy-MM-dd HH:mm");
//     this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
//     //return;
//     console.log('CALC TIMER FROM ORE > ISLOADING : ' + this.isloading, x.DataOraFine.slice(0,4));
//     if(this.isloading==true) {return;}
//     let dtEnd: string ="";
//     dtEnd = x.DataOraFine.toString();
//     if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//     this.isloading=true;
//     //alert(x.DataOraFine);
//     x.DataOraFine = this.datepipe.transform(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Ore)*60)),"yyyy-MM-dd HH:mm");
//     this.isloading=false;
//   }
// }
// calcDurationToHours(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER TO ORE > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
//     let a = x.QtaDurata.split(':');
//     let h = parseInt(a[0]);
//     let m = parseInt(a[1]);
//     if (h>=0 && m>=0) {
//       x.Qta = h + (m/60);
//       x.Qta= x.Qta.toFixed(2);
//       console.log('DURATA: ' + x.QtaDurata + '  QTA: ' + x.Qta);
//       }
//     }
//   this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
//     //return;
//     console.log('CALC TIMER TO ORE > ISLOADING : ' + this.isloading);
//     // let dtEnd: string ="";
//     // dtEnd = x.DataOraFine.toString();
//     //if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
//     let a = x.OreDurata.split(':');
//     let h = parseInt(a[0]);
//     let m = parseInt(a[1]);
//     if (h>=0 && m>=0) {
//         x.Ore = h + (m/60);
//         x.Ore= x.Ore.toFixed(2);
//         console.log('DURATA: ' + x.OreDurata + '  QTA: ' + x.Ore);
//       }
//     }
//     this.isloading=false;
//   }
// }
// msToTime(s) {
//   console.log(s);
//   let ms = s % 1000;
//   s = (s - ms) / 1000;
//   var secs = s % 60;
//   s = (s - secs) / 60;
//   var mins = (s % 60) ;
//   var hrs = (s - mins) / 60;
//   return hrs + ':' + mins;
// }

//#endregion



//#region "CALCOLI TEMPI"
// FUNZIONI PER IL CALCOLO DEI TEMPI E DATE
isloading: boolean = false;
calcTimerFromFine(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcTimerFromFine1 > ISLOADING : ' , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    x.DataOraInizio = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes());
    x.DataOraFine = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes());
    let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
    //this.QtaDurata1= this.pad(parseInt(diff.split(':')[0]), 2) + ':' +  this.pad(parseInt(diff.split(':')[1]),2);
    x.QtaDurata =  new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), parseInt(diff.split(':')[0]), parseInt(diff.split(':')[1]));
    console.log('calcTimerFromFine1 > QtaDurata1 : ' , x.QtaDurata);
    if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
      x.Qta = this.getDecimalFromTime(x.QtaDurata);
      console.log('calcTimerFromFine1 > RESULT : ' , x.QtaDurata, x.Qta, x.DataOraInizio, x.DataOraFine);
  }
  this.isloading=false;
}
calcDurationToHours(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcDurationToHours1 > ISLOADING : ' , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
      x.Qta = this.getDecimalFromTime(x.QtaDurata);
      console.log('calcDurationToHours1 > AFTER CALC : ' , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
      x.DataOraFine = new Date(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Qta)*60)));
      console.log('calcDurationToHours1 > RESULT : ', x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    }
    this.isloading=false;
}
calcTimerFromFineR(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcTimerFromFine1 > ISLOADING : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    x.DataOraInizio = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes());
    x.DataOraFine = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes());
    let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
    //this.OreDurata1= this.pad(parseInt(diff.split(':')[0]), 2) + ':' +  this.pad(parseInt(diff.split(':')[1]),2);
    x.OreDurata =  new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), parseInt(diff.split(':')[0]), parseInt(diff.split(':')[1]));
    console.log('calcTimerFromFine1 > OreDurata1 : ' , x.OreDurata);
    if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
      x.Ore = this.getDecimalFromTime(x.OreDurata);
      console.log('calcTimerFromFine1 > RESULT : ' , x.OreDurata, x.Ore, x.DataOraInizio, x.DataOraFine);
  }
  this.isloading=false;
}
calcDurationToHoursR(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcDurationToHours1 > ISLOADING : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
      x.Ore = this.getDecimalFromTime(x.OreDurata);
      console.log('calcDurationToHours1 > AFTER CALC : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
      x.DataOraFine = new Date(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Ore)*60)));
      console.log('calcDurationToHours1 > RESULT : ', x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    }
    this.isloading=false;
}
 
///////////////////////////////////////////////////////////


getDecimalFromTime(sTime:string){
  var retTime:any;
  var dtTime=new Date(sTime);
  var formattedDate = dtTime.toLocaleTimeString();
   console.log('INTO CALC FUNCTION DECIMAL:' , sTime, formattedDate);
   let a = formattedDate.toString().split(':');
   let h = parseInt(a[0]);
   let m = parseInt(a[1]);
   if (h>=0 && m>=0) {
     retTime = h + (m/60);
     retTime= retTime.toFixed(2);
     //retTime = new Date(new Date(this.DataOraInizio).getFullYear(),new Date(this.DataOraInizio).getMonth(),new Date(this.DataOraInizio).getDate(), h, m);
     return retTime;
   }
}

msToTime(s:any) {
 console.log('msToTime',s);
 let ms = s % 1000;
 s = (s - ms) / 1000;
 var secs = s % 60;
 s = (s - secs) / 60;
 var mins = (s % 60) ;
 var hrs = (s - mins) / 60;
 return hrs + ':' + mins;
}

pad(num:number, size:number): string {
   let s = num+"";
   while (s.length < size) s = "0" + s;
   return s;
}
//#endregion



private msg = "";
public async showToast(_color: string) {

  const toast: any = await this.toastController.create({
    message: this.msg,
    duration: 1500,
    position: "top",
    color: _color
  });
  await toast.present();
  this.msg = "";
}




  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Next App',
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }



  checkboxClick(e){
     this.bCopyToDate = !this.bCopyToDate;
     console.log('chkCopyDate', this.bCopyToDate)
  }


}
