import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 

import { CalendarModule } from 'ion2-calendar';
import { DatePipe } from '@angular/common'
import { RendicontazioniPage } from './rendicontazioni/rendicontazioni.page';
import { RendicontazioniListPage } from './rendicontazioni/rendicontazioni-list/rendicontazioni-list.page';
import { Tab1Page } from './tab1/tab1.page';
import { Tab2Page } from './tab2/tab2.page';
import { Tab3Page } from './tab3/tab3.page';
import { DocumentifePage } from './documentife/documentife.page';
import { DocumentieditPage } from './documentiedit/documentiedit.page';
import { ArticoliEditPage } from './articoli-edit/articoli-edit.page';
import { AnagraficheeditPage } from './anagraficheedit/anagraficheedit.page';
import { DocumentiXmlPage } from './documenti-xml/documenti-xml.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BncmagazzinorighePage } from './bncmagazzinorighe/bncmagazzinorighe.page';
import { DxButtonModule, DxDataGridModule, DxFileManagerModule, DxLoadPanelModule, DxPopupModule, DxToolbarModule, DxTabPanelModule, DxLookupModule, DxPopoverModule, DxToastModule, DxTreeListModule, DxTextAreaModule, DxSpeedDialActionModule, DxSwitchModule, DxTextBoxModule, DxCheckBoxModule, DxDrawerModule, DxValidatorModule, DxDropDownBoxModule, DxTreeViewModule, DxSchedulerModule, DxListModule, DxNumberBoxModule } from 'devextreme-angular';
import { DxoLabelModule } from 'devextreme-angular/ui/nested';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, RouterModule, CalendarModule, ReactiveFormsModule, FormsModule, 
    DxButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DxLoadPanelModule,
    DxDataGridModule,
    DxFileManagerModule,
    DxPopupModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxLookupModule,
    DxPopoverModule,
    DxToastModule,
    DxTreeListModule,
    DxTextAreaModule,
    DxSpeedDialActionModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxDrawerModule,
    DxoLabelModule,
    DxValidatorModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxSchedulerModule,
    DxListModule,
    DxNumberBoxModule,],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Tab1Page, Tab2Page, Tab3Page, DocumentifePage, DocumentieditPage, ArticoliEditPage, AnagraficheeditPage, DocumentiXmlPage,
    RendicontazioniPage, RendicontazioniListPage, BncmagazzinorighePage
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
