import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { GlobalService } from './services/global.service';
import { RendicontazioniPage } from './rendicontazioni/rendicontazioni.page';
import { RendicontazioniListPage } from './rendicontazioni/rendicontazioni-list/rendicontazioni-list.page';
import { Tab1Page } from './tab1/tab1.page';
import { Tab2Page } from './tab2/tab2.page';
import { Tab3Page } from './tab3/tab3.page';
import { DocumentifePage } from './documentife/documentife.page';
import { DocumentieditPage } from './documentiedit/documentiedit.page';
import { ArticoliEditPage } from './articoli-edit/articoli-edit.page';
import { AnagraficheeditPage } from './anagraficheedit/anagraficheedit.page';
import { DocumentiXmlPage } from './documenti-xml/documenti-xml.page';
import itMessages from "devextreme/localization/messages/it.json";
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private navCtrl: NavController,
    public _myglobal : GlobalService,
    private _tbTab1: Tab1Page,
    private _tbTab2: Tab2Page,
    private _tbTab3: Tab3Page,
    private _tbDocFE: DocumentifePage,
    private _tbdocEdit: DocumentieditPage,
    private _tbArtEdit: ArticoliEditPage,
    private _tbAnagEdit: AnagraficheeditPage,
    private _tbDocXml: DocumentiXmlPage,
    private _tbRend: RendicontazioniPage,
    private _tbRendList: RendicontazioniListPage,
    
  ) {
    loadMessages(itMessages);
    locale(navigator.language);
    config({ defaultCurrency: 'EUR' });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  _logout() {

    localStorage.removeItem("Username");
    localStorage.removeItem("Password");
    localStorage.removeItem("IDLicenzaAnagrafica");
    localStorage.removeItem("CodiceUnita");
    localStorage.removeItem("IDUtenteMaster");
    localStorage.removeItem("Username");
    localStorage.removeItem("cnMode");
    localStorage.removeItem("UserType");
    localStorage.removeItem("IDDipendente");
    localStorage.removeItem("Admin");
    localStorage.removeItem("ResponsabileRendicontazione");
    localStorage.removeItem("ResponsabileMagazzini");
    localStorage.removeItem("PIVA");
    localStorage.removeItem("endpointCURRENT");
    localStorage.removeItem("UserData");
    localStorage.removeItem("RendicontazioneMode");

    this._myglobal.Aziende = [];
    this._myglobal.UserData = [];
    this._myglobal.IDLicenzaAnagrafica = -1;
    this._myglobal.myToken = "";
    this._myglobal.PIVA = "";
    this._myglobal.CodiceUnita = "";
    this._myglobal.UserType = "";

    this._tbRend.current_data = "";
    this._tbRend.IDDipendente = 0;
    this._tbRend.ListDipendenti = [];
    this._tbRend.TipiRilevazioni = [];
    this._tbRend.TipiRilevazioniComplessivi = [];
    this._tbRend.StatiRilevazioni = [];
    this._tbRend.selTempo =[];
    this._tbRend.selIDTipoRilevamento = null;
    this._tbRend.selIDStatoRilevazione = null;
    this._tbRend.cmbDip = null;
    this._tbRend.Commesse = [];
    this._tbRend.CommesseFasi = [];
    this._tbRend.selCommessa = null;
    this._tbRend.selIDCommessa = null;
    
    

    this._tbRendList.current_data = "";
    this._tbRendList.IDDipendente = 0;
    this._tbRendList.ListDipendenti = [];
    this._tbRendList.ListFasi = [];
    this._tbRendList.ListAttivita = [];
    this._tbRendList.selAttivita  = [];
    this._tbRendList.selIDAttivita = null;
     

    this._tbTab1.IDGruppoSistema = "CLI";
    this._tbTab1.mySearch ="";
    this._tbTab1.Anagrafiche = [];
    this._tbTab1.AnagraficheTemp = [];
    this._tbTab1.PageNo=1;
    this._tbTab1.Limit=30;

    this._tbTab2.mySearch ="";
    this._tbTab2.ArticoliAll = [];
    this._tbTab2.Articoli = [];
    this._tbTab2.ArticoliTemp = [];
    this._tbTab2.PageNo=1;
    this._tbTab2.Limit=30;

    this._tbTab3.IDTipoGruppo="";
    this._tbTab3.IDGruppoSistema="";
    this._tbTab3.mySearch ="";
    this._tbTab3.DocumentiAll = [];
    this._tbTab3.Documenti = [];
    this._tbTab3.DocumentiTemp = [];
    this._tbTab3.Gruppi = [];
    this._tbTab3.PageNo=1;
    this._tbTab3.Limit=30;

    this._tbdocEdit.IDDocumento= null;
    this._tbdocEdit.Documento =[];
    this._tbdocEdit.DocumentiRighe = [];
    this._tbdocEdit.myDownloadLink = "";
  
    this._tbDocFE.IDGruppoSistema="INV";
    this._tbDocFE.dataFine = "";
    this._tbDocFE.dataInizio  = "";
    this._tbDocFE.mySearch ="";
    this._tbDocFE.Documenti = [];
    this._tbDocFE.Gruppi = [];
    this._tbDocFE.DocumentiStati = [];
    this._tbDocFE.myDownloadLink = "";
  
    this._tbAnagEdit.IDAnagrafica=null;
    this._tbAnagEdit.Anagrafica =[];
  
    this._tbArtEdit.IDArticolo=null;
    this._tbArtEdit.Articolo = [];

    this._tbDocXml.IDGruppoSistema="INV";
    this._tbDocXml.myDownloadLink = "";
    this._tbDocXml.dataInizio = "";
    this._tbDocXml.dataFine = "";
    
    this.navCtrl.navigateRoot('/login');
    // this.router.navigateByUrl('/login');
  }
}
